export const projects = [
  {
    slug: 'biodiv',
    text: 'Pour préserver la vie du sol, Hervé souhaite investir dans un outil de semis direct.<br/><br/>Il permet de semer certaines cultures sans labourer le sol. Ainsi, la structure du sol n’est pas perturbée, ce qui permet de le garder fertile et équilibré.',
    image: 'purina-herve.png',
    title: 'Semer son blé sans labour',
    order: 0,
    place: 'Querrieu, dans la Somme',
    fullname: 'Hervé Mesnard',
    anchor: 'herve',
    video_url: 'https://youtu.be/2ffBMbYD840',
    image_alt: 'home-herve-mesnard.jpg',
  },
  {
    slug: 'des-haies-pour-reguler-les-mulots-dans-les-champs',
    link: 'https://miimosa.com/fr/projects/contribuez-au-financement-d-une-haie-pour-reguler-les-mulots?utm_source=generationregeneration&utm_medium=sitegr&utm_campaign=friskies',
    text: 'Anne-Sophie a pour projet de planter une nouvelle haie afin de réguler naturellement les populations de nuisibles (mulots) pour ses cultures.<br/><br/>Cette haie offrira également à ses vaches de l’ombre en cas de grosses chaleurs et des plantes à vertus médicinales.',
    image: 'purina-anne-sophie.png',
    title: 'Une haie pour ses champs',
    order: 1,
    place: 'Belloy-sur-Somme, dans la Somme',
    fullname: 'Anne-Sophie Niquet',
    anchor: 'annesophie',
    video_url: 'https://youtu.be/Yhd47ut_GpU',
    image_alt: 'home-annesophie-niquet.jpg',
  },
  {
    slug: 'herse-etrille',
    link: 'https://miimosa.com/fr/projects/participez-au-financement-d-une-herse-etrille?utm_source=generationregeneration&utm_medium=sitegr&utm_campaign=friskies',
    text: 'Aurélien souhaite investir dans une herse-étrille. Cet outil permettra d’améliorer le désherbage mécanique de ses cultures et le travail du sol en respectant la nature.<br/><br/>Il favorisera la repousse de l’herbe dans ses prairies.',
    image: 'purina-aurelien-full.jpg',
    title: 'Désherber en respectant les sols',
    order: 2,
    place: 'Marconne, dans le Pas-de-Calais',
    fullname: 'Aurélien Créquy',
    anchor: 'aurelien',
    video_url: 'https://youtu.be/bgBkolFAeMA',
    image_alt: 'home-aurelien-crequy-face.jpg',
  },
]
